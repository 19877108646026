/**
 * Composable that handles the displayed indicator.
 */
export function useDisplayedIndicator() {
   /**
    * List of indicators.
    */
   const indicators = ['conservation', 'restoration', 'climate-conservation', 'climate-restoration'] as const

   /**
    * Selected indicator.
    */
   const selected = useQueryParam<typeof indicators[number]>('indicator', { defaultValue: () => 'conservation' })

   return {
      indicators,
      selected,
   }
}
